<template>
  <div>
    <template v-for="(item, index) in  userGuideObj.user_guide_header">
      <v-container :key="index" :class="$vuetify.breakpoint.lgAndUp ? 'container-width pa-10' :
      $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly ?  'pa-14' : 'pa-5'">
        <v-row class="d-flex align-center" :class="$vuetify.breakpoint.xs ? 'make-column' : ''">
          <v-col  lg="6" xs="12">
            <h1 class="text-h1 font-weight-bold">{{ item.help_title }}</h1>
            <div class="mt-6 text-subtitle-1 ">{{item.help_description}}</div>
          </v-col>
          <v-col lg="6" xs="12" class="mb-7">
            <v-img  width="100%" :src="item.help_image" class="d-flex align-center"></v-img>
          </v-col>
        </v-row>
      </v-container>
      <v-row justify="center" :key="`sub-title-${index}`" class="user-guide-background" >
        <v-container class="pa-xl-15">
          <v-col cols="12">
            <p class="text-center text-color-apply font-weight-bold"
              :class="$vuetify.breakpoint.lgAndUp ? 'text-h2': 'text-h5'"> {{ item.help_subtitle }}
            </p>
          </v-col>
          <v-col cols="12">
              <v-img contain aspect-ratio="2" width="94%" :class="!$vuetify.breakpoint.mdAndDown ? 'ml-16' : ''"  :src="item.userguide_overview_image"></v-img>
          </v-col>
        </v-container>
      </v-row>
    </template>
    <v-container class="mt-5" :class="$vuetify.breakpoint.lgAndUp ? 'pa-10 container-width' : $vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly ?  'pa-14' : 'pa-5'">
      <v-row v-for="(item, index) in  userGuideObj.user_guide_content"
        class="d-flex justify-space-between" :key="`content-${index}`" :class="$vuetify.breakpoint.xsOnly ? 'make-column' : ''">
        <template v-if="item.direction === 'left' ||  $vuetify.breakpoint.mdAndDown">
          <v-col xl="6" lg="6" xs="12" md="12" sm="12" class=" d-flex align-center pr-0 reveal-card fade-bottom-card">
            <v-container>
              <v-card flat class=" justify-text">
                <v-row class="ma-0">
                  <p class="text-h4 text-left font-weight-bold">{{ item.title }}</p>
                  <span class="mt-4 align-text" style="line-height: 200%;">{{ item.user_guide_description }} </span>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
          <v-col xl="6" lg="6" xs="12" md="12" sm="12" class="reveal-card fade-bottom-card pa-0">
            <v-img contain aspect-ratio="2" width="100%" :src="item.user_guide_image" class="d-flex align-center mb-15"></v-img>
          </v-col>
        </template>
          <template v-else-if="item.direction === 'right' && $vuetify.breakpoint.lgAndUp">
          <v-col xl="6" lg="6" xs="12" md="6" sm="12" class="pa-0 reveal-card fade-bottom-card">
            <v-img contain aspect-ratio="2" width="100%" :src="item.user_guide_image" class="d-flex align-center"></v-img>
          </v-col>
          <v-col xl="6" lg="6" xs="12" md="6" sm="12" class="d-flex align-center pl-0 reveal-card fade-bottom-card">
            <v-container>
              <v-card flat class="pa-5">
                <v-row class="ma-0">
                  <p class="text-h4 text-left font-weight-bold">{{ item.title }}</p>
                  <p class="mt-4 align-text" style="line-height: 200%;">{{ item.user_guide_description }} </p>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
import CommonFunctions from '../../mixins/animation'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      userGuideObj: {}
    }
  },
  mounted () {
    this.userGuide()
    this.scrollToTop()
  },
  methods: {
    userGuide () {
      api.get('/user_guide')
        .then((response) => {
          this.userGuideObj = response.data
          // Add 'direction' property to each element
          if (this.userGuideObj && this.userGuideObj.user_guide_content) {
            this.userGuideObj.user_guide_content.forEach((element, index) => {
              element.direction = index % 2 === 0 ? 'left' : 'right'
            })
          }
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    }
  }
}
</script>
<style>
/* .heavy-epuipment-background {
   background: #48424F !important;
} */
.gradient-text-heavy-epuipment {
      background: linear-gradient(to right, #FFA726, #FFDAA4);
      color: #000000;
    }
.justify-text {
  text-align: justify !important;
   /* line-height: 1.8 !important; */
}
.text-color-apply {
  color: #260041 !important;
}
.align-text {
    text-align: justify !important;
}
.user-guide-background {
    background: linear-gradient(0deg, rgba(202, 224, 240, 0.32), rgba(192, 213, 229, 0.32)),
                linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
  }
.container-width {
  width: 60% !important;
}
</style>
